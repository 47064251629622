import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {},
  mutations: {},
  actions: {
    async fetchNews(context, payload) {
      context.commit('app/SET_LOADING', true, { root: true })
      return axios.get('json/lk/info/news/grid', {
        params: {
          zpage: payload.currentPage,
          zlimit: payload.perPage,
          zstart: payload.offset,
          xparam1: payload.xparam1,
          xparam2: payload.xparam2,
          xparam3: payload.xparam3,
        },
      })
        .then(response => {
          context.commit('app/SET_LOADING', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING', false, { root: true })
        })
    },
    async fetchNewsItem(context, payload) {
      context.commit('app/SET_LOADING', true, { root: true })
      return axios.get(`htm/lk/info/news/page/detail/${payload.id}`)
        .then(response => {
          context.commit('app/SET_LOADING', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING', false, { root: true })
        })
    },
    async addNews(context, payload) {
      context.commit('app/SET_LOADING_CREATE', true, { root: true })
      let x6datePost
      let x7datePostEnd

      if (payload.x6datePost) {
        const parts = payload.x6datePost.split('-')
        x6datePost = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      if (payload.x7datePostEnd) {
        const parts = payload.x7datePostEnd.split('-')
        x7datePostEnd = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      return axios.post('json/lk/info/news/add', {
        ...payload,
        x6datePost,
        x7datePostEnd,
      })
        .then(response => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })
        })
    },
    async updateNews(context, payload) {
      context.commit('app/SET_LOADING_UPDATE', true, { root: true })
      let x6datePost
      let x7datePostEnd

      if (payload.data.x6datePost) {
        const parts = payload.data.x6datePost.split('-')
        x6datePost = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      if (payload.data.x7datePostEnd) {
        const parts = payload.data.x7datePostEnd.split('-')
        x7datePostEnd = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      return axios.put(`json/lk/info/news/upd/${payload.id}`, {
        ...payload.data,
        x6datePost,
        x7datePostEnd,
      })
        .then(response => {
          context.commit('app/SET_LOADING_UPDATE', false, { root: true })

          const { data } = response

          if (data.success) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Оповещение',
                icon: 'InfoIcon',
                variant: 'success',
                text: data.data.msg,
              },
            })
          }

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_UPDATE', false, { root: true })
          return false
        })
    },
    async deleteNews(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить новость?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`json/lk/info/news/del/${payload}`)
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value.data
          if (data[0]) {
            if (data[0].Error) {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: data[0].Error,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'success',
                text: data[0].msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'error',
              text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }

          return true
        }

        return false
      })
    },
    async updateNewsStatus(context, payload) {
      let msg = 'Опубликовать новость?'

      if (payload.status === 0) {
        msg = 'Снять новость с публикации?'
      }

      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`json/lk/info/news/set/status/${payload.id}`, {
            x1iStatus: payload.status,
          })
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value.data
          if (data[0]) {
            if (data[0].Error) {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: data[0].Error,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'success',
                text: data[0].msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'error',
              text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }

          return true
        }

        return false
      })
    },
    // async fetchDocuments(context, payload) {
    async fetchDocuments(context) {
      context.commit('app/SET_LOADING', true, { root: true })
      // return axios.get('json/lk/info/docs/grid', {
      //   params: {
      //     zpage: payload.currentPage,
      //     zlimit: payload.perPage,
      //     zstart: payload.offset,
      //     xparam1: payload.xparam1,
      //     xparam2: payload.xparam2,
      //     xparam3: payload.xparam3,
      //   },
      // })
      return axios.get('json/lk/info/docs/grid')
        .then(response => {
          context.commit('app/SET_LOADING', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING', false, { root: true })
        })
    },
    async deleteDocument(context, payload) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить документ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`json/lk/info/docs/del/${payload}`)
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value.data
          if (data[0]) {
            if (data[0].Error) {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: data[0].Error,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'success',
                text: data[0].msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'error',
              text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }

          return true
        }

        return false
      })
    },
    async updateDocumentStatus(context, payload) {
      let msg = 'Опубликовать документ?'

      if (payload.status === 0) {
        msg = 'Снять документ с публикации?'
      }

      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.put(`json/lk/info/docs/set/status/${payload.id}`, {
            x1iStatus: payload.status,
          })
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { data } = result.value.data
          if (data[0]) {
            if (data[0].Error) {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'error',
                text: data[0].Error,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            } else {
              // eslint-disable-next-line no-underscore-dangle
              this._vm.$swal({
                icon: 'success',
                text: data[0].msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$swal({
              icon: 'error',
              text: 'Возникла непредвиденная ошибка. Попробуйте еще раз',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }

          return true
        }

        return false
      })
    },
    async addDocument(context, payload) {
      context.commit('app/SET_LOADING_CREATE', true, { root: true })
      let x6datePost

      if (payload.x6datePost) {
        const parts = payload.x6datePost.split('-')
        x6datePost = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      return axios.post('json/lk/info/docs/add', {
        ...payload,
        x6datePost,
      })
        .then(response => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })
          return false
        })
    },
    async updateDocument(context, payload) {
      context.commit('app/SET_LOADING_CREATE', true, { root: true })
      let x6datePost

      if (payload.data.x6datePost) {
        const parts = payload.data.x6datePost.split('-')
        x6datePost = `${parts[2]}.${parts[1]}.${parts[0]}`
      }

      return axios.put(`json/lk/info/docs/upd/${payload.id}`, {
        ...payload.data,
        x6datePost,
      })
        .then(response => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })

          return response
        })
        .catch(() => {
          context.commit('app/SET_LOADING_CREATE', false, { root: true })
          return false
        })
    },
  },
}
